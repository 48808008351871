import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAjAO4RsTcw5wmgTuyfyVZwQw8k2VBBOYU",
    authDomain: "myweddingplanner-13ee6.firebaseapp.com",
    projectId: "myweddingplanner-13ee6",
    storageBucket: "myweddingplanner-13ee6.appspot.com",
    messagingSenderId: "856547865587",
    appId: "1:856547865587:web:cbf2de321621c20b195886",
    measurementId: "G-L2T6603WD9",
    databaseURL: 'https://myweddingplanner-13ee6-default-rtdb.europe-west1.firebasedatabase.app/'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);
export default app;
