import React, {useEffect, useState} from 'react';
import {FormGroup, Label, Input, Col, Row, Button} from 'reactstrap';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import Autocomplete from 'react-google-autocomplete';
import TimeAndDate from "../components/TimeAndDate";
import {db} from "../firebase";
import {ref, set, onValue} from "firebase/database";
import {useUserAuth} from "../context/UserAuthContext";
import _ from "lodash";
import moment from "moment";
import { ReactComponent as LocationImage } from "./../assets/img/location.svg";

const AddLocation = ({ google }) => {
    const [zoom, setZoom] = useState(2);
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [place, setPlace] = useState(null);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState('');
    const [hidden, setHidden] = useState(true);
    const {user} = useUserAuth();

    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
    };

    const handleTimeChange = (event) => {
        setTime(event.target.value);
    };

    useEffect(() => {
        if (user && !_.isEmpty(user)) {
            if (user && !_.isEmpty(user)) {
                onValue(ref(db, `/users/${user.uid}/location/`), (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        let place = JSON.parse(data.place);
                        setTime(data?.time || '');
                        setDate(data?.date ? moment(data.date).toDate() : null);
                        if (data?.place) {
                            placeFromDB(place);
                            setHidden(false);
                        }
                    }
                });
            }
        }
    }, [user])

    const saveData = () => {
        if (user && !_.isEmpty(user)) {
            if (place) {
                set(ref(db, `/users/${user.uid}/location/`), {
                    place: JSON.stringify(place),
                    date: moment(date).valueOf() || null,
                    time: time
                }).then(r => console.log('res: ', r))
                    .catch(e => console.log('err: ', e));
            }
        }
    }

    const onPlaceSelected = (place) => {
        setPlace(place);
        setCoordinates({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng() }
        );
        setZoom(18);
    };

    const placeFromDB = (place) => {
        setPlace(place);
        setCoordinates({
            lat: place.geometry.location.lat,
            lng: place.geometry.location.lng }
        );
        setZoom(18);
    };

    if (hidden) {
        return (
            <Row>
                <Col>
                    <h5 className={'mb-4'}>Time, Date & Location</h5>
                    <div className="text-center align-items-center justify-content-center">
                        <div style={{width: '30%', marginLeft: "auto", marginRight: "auto", textAlign: 'center'}}>
                            <LocationImage />
                        </div>
                        <h3>Second step to every wedding is setting THE date</h3>
                        <h5>Get started by setting the date, time and the location of your special day</h5>
                        <Button color={'primary'} onClick={() => setHidden(false)}>Start</Button>
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={4} lg={6}
                    className={'d-flex flex-column justify-content-between'}
                >
                    <div>
                        <h5 className={'mb-4'}>Time, Date & Location</h5>
                        <TimeAndDate
                            date={date}
                            setDate={setDate}
                            time={time}
                            setTime={setTime}
                            handleDateChange={handleDateChange}
                            handleTimeChange={handleTimeChange}
                        />
                        <FormGroup row>
                            <Label for="address" sm={3}>
                            Address
                            </Label>
                            <Col sm={9}>
                                {
                                    place && !_.isEmpty(place) ?
                                        <Input type="text"
                                            name="address" id="address"
                                            value={place.formatted_address}
                                            onClick={() => setPlace(null)}
                                            readOnly
                                        /> :
                                        <Autocomplete
                                            className="form-control"
                                            apiKey={'AIzaSyDDAhIpel9widD7VblaQ2zFStzOEEzU4wg'}
                                            placeholder="Enter an address"
                                            options={{
                                                types: ['geocode', 'establishment'],
                                                strictBounds: false,
                                            }}
                                            onPlaceSelected={onPlaceSelected}
                                        />
                                }
                            </Col>
                        </FormGroup>
                    </div>
                    <div className={'d-none d-md-block'}>
                        <Button color={'primary'} block onClick={saveData}>Save Data</Button>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6}>
                    <h5 className={'mb-lg-4'}>Map</h5>
                    <div style={{width: 'auto', height: '300px'}} className={'outlineCardNoHover'}>
                        <Map google={google}
                            center={coordinates} zoom={zoom}
                            style={{borderRadius: '0.25rem'}}
                            containerStyle={{position: 'relative', width: '100%', height: '100%', borderRadius: '0.625rem'}}
                        >
                            {coordinates.lat && coordinates.lng && <Marker position={coordinates} />}
                        </Map>
                    </div>
                    <small>You need to set the place in order to save your data.</small>
                </Col>
            </Row>
            <Row className={'d-md-none mt-3'}>
                <Col>
                    <Button color={'primary'} block onClick={saveData}>Save Data</Button>
                </Col>
            </Row>
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDDAhIpel9widD7VblaQ2zFStzOEEzU4wg',
})(AddLocation);
