import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Label, Input, Row, Col, InputGroup} from 'reactstrap';
import {onValue, ref, update} from "firebase/database";
import {db} from "../firebase";
import {useUserAuth} from "../context/UserAuthContext";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Playlist = () => {
    const {user} = useUserAuth();
    const [songs, setSongs] = useState([]);
    const [songName, setSongName] = useState('');
    const [artistName, setArtistName] = useState('');

    useEffect(() => {
        onValue(ref(db, `/users/${user.uid}/music/playlist`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setSongs(data.songs)
            }
        });
    }, [user]);

    const addSongToPlaylist = () => {
        setSongs([...songs, { songName, artistName }]);
        setSongName('');
        setArtistName('');
    };

    const handleInputChange = (event) => {
        switch(event.target.name) {
        case 'songName':
            setSongName(event.target.value);
            break;
        case 'artistName':
            setArtistName(event.target.value);
            break;
        default:
            break;
        }
    };

    const deleteSongFromPlaylist = (index) => {
        const newSongs = songs.filter((song, i) => i !== index);
        setSongs(newSongs);
    };

    useEffect(() => {
        if (!!user.uid) {
            update(ref(db, `/users/${user.uid}/music/playlist`), {
                songs
            })
        }
    }, [songs, user])

    return (
        <Row>
            <Col md={4}>
                <h5 className={'mb-4 mt-4'}>Add new songs</h5>
                <div className={'outlineCardNoHover p-4'}>
                    <FormGroup>
                        <Label for="artistName">Artist</Label>
                        <Input type="text" name="artistName" id="artistName" value={artistName} onChange={handleInputChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="songName">Song</Label>
                        <Input type="text" name="songName" id="songName" value={songName} onChange={handleInputChange} />
                    </FormGroup>
                    <Button type="button" color={'primary'} onClick={addSongToPlaylist} className={'mr-2'}>Add Song</Button>
                </div>
            </Col>
            <Col md={8}>
                <Row>
                    <Col>
                        <h5 className={'mb-4 mt-4'}>Playlist</h5>
                    </Col>
                    <Col className={'d-flex align-items-center justify-content-end'}>
                        {
                            songs.length > 0 ?
                                <ExcelFile element={<Button size={'sm'} color={'success'}>Download Playlist</Button>}>
                                    <ExcelSheet data={songs || []} name="Playlist">
                                        <ExcelColumn label="Artist" value="artistName"/>
                                        <ExcelColumn label="Song" value="songName"/>
                                    </ExcelSheet>
                                </ExcelFile> : null
                        }
                    </Col>
                </Row>
                <div className={'outlineCardNoHover p-4'}>
                    {songs.map((song, index) => (
                        <div key={index} className={'mb-2'}>
                            <InputGroup>
                                <Input bsSize='sm' type="text" name="songName" id={`songName${index}`} value={song.songName + ' - ' + song.artistName} readOnly />
                                <Button type="button" size={'sm'} color={'danger'} onClick={() => deleteSongFromPlaylist(index)} className={'ml-2'}>Remove</Button>
                            </InputGroup>
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    );
}

export default Playlist;
