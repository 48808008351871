import {IoPeopleCircleOutline} from "react-icons/io5";
import {BiChurch, BiMoneyWithdraw} from "react-icons/bi";
import {IoIosPeople, IoMdMusicalNotes} from "react-icons/io";
import {MdOutlinePeopleAlt, MdRestaurantMenu} from "react-icons/md";
import {GiRoundTable} from "react-icons/gi";
import {BsFlower1} from "react-icons/bs";
import {RiListOrdered} from "react-icons/ri";
import React from "react";

const sideMenuItems = [
    {
        route: 'couple',
        name: 'Couple',
        icon: <IoPeopleCircleOutline />
    },
    {
        route: 'witnesses',
        name: 'Best Man & Maid of Honor',
        icon: <MdOutlinePeopleAlt />,
    },
    {
        route: 'location',
        name: 'Location & Date',
        icon: <BiChurch />,
    },
    {
        route: 'guests',
        name: 'Guests',
        icon: <IoIosPeople />,
    },
    {
        route: 'table-plan',
        name: 'Table Plan',
        icon: <GiRoundTable />,
    },
    {
        route: 'food-menu',
        name: 'Food & Drinks',
        icon: <MdRestaurantMenu />,
    },
    {
        route: 'decorations',
        name: 'Decorations',
        icon: <BsFlower1 />,
    },
    {
        route: 'music',
        name: 'Music',
        icon: <IoMdMusicalNotes />,
    },
    {
        route: 'budget',
        name: 'Budget',
        icon: <BiMoneyWithdraw />,
    },
    {
        route: 'itinerary',
        name: 'Itinerary',
        icon: <RiListOrdered />,
    }
]

export default sideMenuItems;
