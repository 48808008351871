import React from 'react';
import { Button } from 'reactstrap';
import { remove, ref as dbRef } from "firebase/database";
import {useUserAuth} from "../context/UserAuthContext";
import {db} from "../firebase";

const BudgetItem = ({ cost, costKey }) => {
    const {user} = useUserAuth();
    const handleDelete = async () => {
        await remove(dbRef(db, `/users/${user.uid}/budget/${costKey}`));
    };

    return (
        <tr>
            <td>{cost.name}</td>
            <td>{cost.amount}</td>
            <td>{cost.currency}</td>
            <td align="right">
                <a className={'btn btn-outline-primary btn-sm'}
                    href={cost.invoiceURL}
                    target="_blank"
                    rel="noreferrer">
                    Download
                </a>
            </td>
            <td align="right">
                <Button size={'sm'} outline color={'danger'} onClick={handleDelete}>Delete</Button>
            </td>
        </tr>
    );
}

export default BudgetItem;
