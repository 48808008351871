import React from 'react';
import { useDrag } from 'react-dnd';
import { MdFamilyRestroom, MdPeople, MdPerson } from 'react-icons/md';

const Guest = ({ guest }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'guest',
        item: { guest },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const displayName = () => {
        if (guest.familyName) {
            return <span><MdFamilyRestroom />{' ' + guest.familyName} ({guest.amountOfPeople})</span>;
        } else if (guest.plusOneName) {
            return <span><MdPeople /> {guest.fullName} + {guest.plusOneName} (2)</span>;
        } else {
            return <span><MdPerson />{' ' + guest.fullName} (1)</span>;
        }
    };

    return (
        <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <div className={'outlineCard p-2 mb-1 d-flex justify-content-between align-items-center'} style={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
                {displayName()}
            </div>
        </div>
    );
};

export default Guest;
