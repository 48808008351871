import React, {useEffect, useState} from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {onValue, ref, update} from "firebase/database";
import {db} from "../firebase";
import {useUserAuth} from "../context/UserAuthContext";

const ArtistInfo = () => {
    const {user} = useUserAuth();
    const [artist, setArtist] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [fees, setFees] = useState('');

    useEffect(() => {
        onValue(ref(db, `/users/${user.uid}/music/artist`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setArtist(data?.artist || '')
                setContactInfo(data?.contactInfo || '')
                setFees(data?.fees || '')
            }
        });
    }, [user]);

    const updateArtist = async () => {
        if (!!user.uid) {
            await update(ref(db, `/users/${user.uid}/music/artist`), {
                artist,
                contactInfo,
                fees
            })
        }
    };

    const handleInputChange = (event) => {
        switch(event.target.name) {
        case 'artist':
            setArtist(event.target.value);
            break;
        case 'contactInfo':
            setContactInfo(event.target.value);
            break;
        case 'fees':
            setFees(event.target.value);
            break;
        default:
            break;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Push this data to Firebase
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="artist">DJ/Band Name</Label>
                <Input type="text" name="artist" id="artist" value={artist} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
                <Label for="contactInfo">Contact Info</Label>
                <Input type="text" name="contactInfo" id="contactInfo" value={contactInfo} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
                <Label for="fees">Fees</Label>
                <Input type="text" name="fees" id="fees" value={fees} onChange={handleInputChange} />
            </FormGroup>
            <Button onClick={updateArtist}>Save</Button>
        </Form>
    );
}

export default ArtistInfo;
