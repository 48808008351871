import React, {useEffect, useState} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input, Col, InputGroup, InputGroupText,
} from 'reactstrap';
import {ref, update} from "firebase/database";
import {db} from "../firebase";
import {useUserAuth} from "../context/UserAuthContext";

function EditGuest({
    isOpen, toggle, data
}) {
    const { user } = useUserAuth();
    const [familyName, setFamilyName] = useState(data?.familyName || '');
    const [amountOfPeople, setAmountOfPeople] = useState(data?.amountOfPeople || null);
    const [fullName, setFullName] = useState(data?.fullName || '');
    const [plusOneName, setPlusOneName] = useState(data?.plusOneName  || '');
    const [plusOne, setPlusOne] = useState(data?.plusOne || false);

    const updateGuest = async () => {
        if (data?.familyName) {
            update(ref(db, `/users/${user.uid}/guests/${data.uuid}`), {
                familyName: familyName,
                amountOfPeople: parseInt(amountOfPeople)
            })
                .then(() => {
                    toggle();
                })
                .catch((err) => {
                    console.log(err.message)
                })
        } else {
            const payload = () => {
                if (plusOne) {
                    return {
                        fullName: fullName,
                        plusOne: true,
                        plusOneName: plusOneName,
                        amountOfPeople: 2
                    }
                } else {
                    return {
                        fullName: fullName,
                        plusOne: false,
                        amountOfPeople: 1
                    }
                }
            }

            update(ref(db, `/users/${user.uid}/guests/${data.uuid}`), payload())
                .then(() => {
                    toggle();
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
    }

    useEffect(() => {
        if (!plusOne) {
            setPlusOneName('')
        }
    }, [plusOne])

    return (
        <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
            <ModalHeader toggle={toggle}>Edit Data</ModalHeader>
            <ModalBody>
                {
                    data?.familyName ?
                        <>
                            <FormGroup row>
                                <Label for="familyName" sm={4}>
                                Family Name
                                </Label>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="familyName"
                                        id="familyName"
                                        value={familyName}
                                        onChange={(e) => setFamilyName(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="amountOfPeople" sm={4}>
                                Amount of People
                                </Label>
                                <Col sm={8}>
                                    <Input
                                        type="number"
                                        name="amountOfPeople"
                                        id="amountOfPeople"
                                        value={amountOfPeople}
                                        onChange={(e) => setAmountOfPeople(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                        </> :
                        <>
                            <FormGroup row>
                                <Label for="fullName" sm={4}>
                                    Full Name
                                </Label>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="fullName"
                                        id="fullName"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="plusOne" sm={4}>
                                    Plus One
                                </Label>
                                <Col sm={8}>
                                    <InputGroup>
                                        <InputGroupText>
                                            <Input
                                                addon
                                                type="checkbox"
                                                name="plusOne"
                                                id="plusOne"
                                                checked={plusOne}
                                                onChange={(e) => setPlusOne(e.target.checked)}
                                            />
                                        </InputGroupText>
                                        <Input
                                            disabled={!plusOne}
                                            placeholder={'Please select the checkbox and type in the full name of your plus one'}
                                            type="text"
                                            name="plusOneName"
                                            id="plusOneName"
                                            value={plusOneName}
                                            onChange={(e) => setPlusOneName(e.target.value)}
                                        />
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                        </>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={updateGuest}>
                    Save
                </Button>
                {' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default EditGuest;
