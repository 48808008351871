import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import { ReactComponent as DecorationsImage } from "./../assets/img/decorationsImage.svg";

const Decorations = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="text-center align-items-center justify-content-center">
                            <div style={{width: '35%', marginLeft: "auto", marginRight: "auto", textAlign: 'center'}}>
                                <DecorationsImage />
                            </div>
                            <h3>Welcome to <strong>MyWeddingPlanner</strong> app</h3>
                            <h5>Get started by clicking the 'Couple' menu item on your left hand side</h5>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Decorations;
