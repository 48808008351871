import React, {useEffect, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Table from '../components/Table';
import {db} from '../firebase';
import {onValue, ref, update} from 'firebase/database';
import {Button, Col, Form, FormGroup, Input, InputGroup, Row} from "reactstrap";
import GuestList from "../components/GuestList";
import {useUserAuth} from "../context/UserAuthContext";
import { ReactComponent as TablePlanImage } from "./../assets/img/tablePlan.svg";
import {useNavigate} from "react-router-dom";

const TablePlan = () => {
    const {user} = useUserAuth();
    const navigate = useNavigate();
    const [guests, setGuests] = useState([]);
    const [unassignedGuests, setUnassignedGuests] = useState([]);
    const [tableSeats, setTableSeats] = useState('');
    const [tables, setTables] = useState([]);


    useEffect(() => {
        getGuests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (user && guests.length) {
            getTables();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, guests]);

    useEffect(() => {
        if (guests.length) {
            setUnassignedGuests(guests.filter((guest) =>
                !tables.some((table) => table.guests && table.guests.includes(guest.id))
            ))
        }
    }, [guests, tables])

    const getGuests = () => {
        if (user.uid) {
            const guestsRef = ref(db, `/users/${user.uid}/guests`);
            onValue(guestsRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const loadedGuests = [];

                    for (const key in data) {
                        loadedGuests.push({
                            id: key,
                            ...data[key],
                        });
                    }

                    setGuests(loadedGuests);
                }
            });
        }
    }

    const getTables = () => {
        if (user.uid) {
            const guestsRef = ref(db, `/users/${user.uid}/table_plan`);
            onValue(guestsRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {

                    const result = [];

                    for (const key in data) {
                        if (data.hasOwnProperty(key)) {
                            result.push({
                                id: key,
                                name: data[key].name,
                                seats: data[key].seats,
                                guests: data[key].guests || []
                            });
                        }
                    }

                    setTables(result)
                }
            });
        }
    }

    const handleGuestDrop = async (guest, table) => {
        // Find the table
        const foundTable = tables.find((t) => t.id === table.id);

        if (foundTable && user.uid) {
            let tableData;
            onValue(ref(db, `/users/${user.uid}/table_plan/${table.id}`), (snapshot) => {
                tableData = snapshot.val();
            });

            const occupiedSeats = (tableData?.guests || []).reduce(
                (acc, currGuestId) =>
                    acc +
                    guests.find((g) => g.id === currGuestId).amountOfPeople,
                0
            );

            const availableSeats = table.seats - occupiedSeats;

            if (
                guest.amountOfPeople <= availableSeats &&
                !tableData?.guests?.includes(guest.id)
            ) {
                await update(ref(db, `/users/${user.uid}/table_plan/${table.id}`), {
                    ...tableData,
                    guests: [
                        ...tableData?.guests || [],
                        guest.id || ''
                    ]
                });
                setUnassignedGuests((prevGuests) => prevGuests.filter((g) => g.id !== guest.id));
            }
        }
    };

    const handleGuestUnassign = async (guest, table_id) => {

        if (table_id && user.uid) {
            let tableData;
            onValue(ref(db, `/users/${user.uid}/table_plan/${table_id}`), (snapshot) => {
                tableData = snapshot.val();
            });

            if (tableData?.guests?.includes(guest.id)) {
                // Remove the guest ID from the table's guests array
                const updatedGuests = tableData.guests.filter((gId) => gId !== guest.id);

                // Update the table's guests array in the database
                await update(ref(db, `/users/${user.uid}/table_plan/${table_id}`), {
                    ...tableData,
                    guests: updatedGuests,
                });

                getGuests();
            }
        }
    };

    const handleAddTable = async (e) => {
        e.preventDefault();

        // Generate a unique table ID and name
        const tableId = `table-${Date.now()}`;
        const tableName = `Table ${tables.length + 1}`;

        // Create a new table object
        const newTable = {
            id: tableId,
            name: tableName,
            seats: parseInt(tableSeats, 10),
        };

        // Add the new table to the list of tables
        setTables((prevTables) => [...prevTables, newTable]);

        // Save the new table to Firebase
        if (user.uid) {
            await update(ref(db, `/users/${user.uid}/table_plan/`), {
                [tableId]: {
                    name: tableName,
                    seats: parseInt(tableSeats, 10),
                    guest: {}
                },
            });
        }

        // Clear the form input
        setTableSeats('');
    };

    const handleRemoveTable = async (tableId) => {
        // Update tables state
        setTables((prevTables) => prevTables.filter((table) => table.id !== tableId));

        // Remove table from Firebase
        if (user.uid) {
            await update(ref(db, `/users/${user.uid}/table_plan/`), {
                [tableId]: null,
            });
        }
    };

    if (unassignedGuests.length < 1 && tables.length < 1) {
        return (
            <>
                <Row>
                    <Col>
                        <h5 className={'mb-4'}>Table plan</h5>
                    </Col>
                </Row>
                <Row>
                    <Col className={'text-center align-items-center justify-content-center'}>
                        <div style={{width: '35%', marginLeft: "auto", marginRight: "auto", textAlign: 'center'}}>
                            <TablePlanImage />
                        </div>
                        <div>
                            <h5>Get started by adding some guests</h5>
                            <Button color={'primary'} onClick={() => navigate('/add-guests')}>Add guests</Button>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    return <DndProvider backend={HTML5Backend}>
        <Row>
            <Col md={4}>
                <h5 className={'mb-4'}>Guests</h5>
                <GuestList guests={unassignedGuests} />
            </Col>
            <Col md={8}>
                <Row>
                    <Col md={4}>
                        <h5 className={'mb-4'}>Tables</h5>
                    </Col>
                    <Col md={8}>
                        {
                            tables.length > 0 ?
                                <Form onSubmit={handleAddTable}>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input
                                                min={1}
                                                bsSize={'sm'}
                                                type="number"
                                                name="tableSeats"
                                                placeholder={'Enter the amount of seats'}
                                                id="tableSeats"
                                                value={tableSeats}
                                                onChange={(e) => setTableSeats(e.target.value)}
                                            />
                                            <Button size={'sm'} type="submit">Add Table</Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Form> : null
                        }
                    </Col>
                </Row>
                <div className="table-plan" style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100vh - 300px)' }}>
                    <Row>
                        {
                            (tables.length < 1) ?
                                <Col className={'text-center align-items-center justify-content-center'}>
                                    <div style={{width: '50%', marginLeft: "auto", marginRight: "auto", textAlign: 'center'}}>
                                        <TablePlanImage />
                                        {
                                            unassignedGuests.length > 0 ?
                                                <>
                                                    <h6 className={'mb-4'}>Create your first table</h6>
                                                    <Form onSubmit={handleAddTable}>
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <Input
                                                                    min={1}
                                                                    bsSize={'sm'}
                                                                    type="number"
                                                                    name="tableSeats"
                                                                    placeholder={'Enter the amount of seats'}
                                                                    id="tableSeats"
                                                                    value={tableSeats}
                                                                    onChange={(e) => setTableSeats(e.target.value)}
                                                                />
                                                                <Button size={'sm'} type="submit">Add Table</Button>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Form>
                                                </> : null
                                        }
                                    </div>
                                </Col>
                                : null
                        }
                        {tables.map((table) => <Col md={6} key={table.id}>
                            <Table
                                key={table.id}
                                table={table}
                                onGuestDrop={handleGuestDrop}
                                seats={table.seats}
                                handleGuestUnassign={handleGuestUnassign}
                                seatedGuests={guests.filter(
                                    (guest) => table.guests && table.guests.includes(guest.id)
                                )}
                                onRemoveTable={handleRemoveTable}
                            />
                        </Col>)}
                    </Row>
                </div>
            </Col>
        </Row>
    </DndProvider>;
};

export default TablePlan;
