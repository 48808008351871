import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import {ref, set} from "firebase/database";
import {db} from "../firebase";
import {useUserAuth} from "../context/UserAuthContext";

function DeleteGuest({
    isOpen, toggle, data
}) {
    const { user } = useUserAuth();

    const deleteGuest = async () => {
        if (user && user?.uid) {
            set(ref(db, `/users/${user.uid}/guests/${data.uuid}`), null)
                .then(() => {
                    toggle();
                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
            <ModalHeader toggle={toggle}>Delete Guest</ModalHeader>
            <ModalBody>
                Are you sure you want to delete this guest?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={deleteGuest}>
                    Yes
                </Button>
                {' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default DeleteGuest;
