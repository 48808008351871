import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import Food from "../components/Food";
import Drinks from "../components/Drinks";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import {useUserAuth} from "../context/UserAuthContext";
import { ReactComponent as FoodImage } from "./../assets/img/food.svg";

const FoodMenu = () => {
    const {user} = useUserAuth();
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        onValue(ref(db, `/users/${user.uid}/menu`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setHidden(false)
            }
        });
    }, [user]);

    if (hidden) {
        return (
            <Row>
                <Col>
                    <h5 className={'mb-4'}>Food & Drinks</h5>
                    <div className="text-center align-items-center justify-content-center">
                        <div style={{width: '30%', marginLeft: "auto", marginRight: "auto", textAlign: 'center'}}>
                            <FoodImage />
                        </div>
                        <h3>You can't get married on an empty stomach</h3>
                        <h5>Let's get you started with food and drinks...</h5>
                        <Button color={'primary'} onClick={() => setHidden(false)}>Yummy!</Button>
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <div>
            <Row>
                <Col>
                    <h5 className={'mb-4'}>Food</h5>
                </Col>
                <Col>
                    <h5 className={'mb-4'}>Drinks</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Food />
                </Col>
                <Col>
                    <Drinks />
                </Col>
            </Row>
        </div>
    );
};

export default FoodMenu;
