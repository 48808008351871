import {activeRouteContext} from "../context/RouteContext";

const SideMenuItem = ({route, name, icon, onClick}) => {
    let {activeRoute} = activeRouteContext();

    const activeClass = () => {
        if (activeRoute === '/' + route) {
            return 'mb-3 outlineCard p-3 outlineCardActive'
        }
        return 'mb-3 outlineCard p-3';
    }

    const activeText = () => {
        if (activeRoute === '/' + route) {
            return 'mb-0 activeTextColor'
        }
        return 'mb-0';
    }

    return (
        <div onClick={() => onClick()}
            style={{cursor: 'pointer'}}
            className={activeClass()}
        >
            <h6 className={activeText()}>{icon} {name}</h6>
        </div>
    )
}

export default SideMenuItem;
