import { createContext, useContext, useEffect, useState } from "react";
import {useLocation} from "react-router";

const routeContext = createContext();

export function RouteContextProvider({ children }) {
    const [activeRoute, setActiveRoute] = useState(null);
    const location = useLocation();

    useEffect(() => {
        setActiveRoute(location.pathname)
    }, [location]);

    return (
        <routeContext.Provider
            value={{ activeRoute }}
        >
            {children}
        </routeContext.Provider>
    );
}

export function activeRouteContext() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useContext(routeContext);
}
