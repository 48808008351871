import React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'shards-ui/dist/css/shards.min.css';

const TimeAndDate = ({date, time, handleDateChange, handleTimeChange}) => {

    return (
        <>
            <FormGroup row>
                <Label for="date" sm={3}>
                    Date
                </Label>
                <Col sm={9}>
                    <DatePicker
                        className={'form-control'}
                        id="date"
                        selected={date}
                        onChange={handleDateChange}
                        dateFormat="MMMM d, yyyy"
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="time" sm={3}>
                    Time
                </Label>
                <Col sm={9}>
                    <Input
                        type="time"
                        name="time"
                        id="time"
                        placeholder="Select a time"
                        value={time}
                        onChange={handleTimeChange}
                    />
                </Col>
            </FormGroup>
        </>
    );
};

export default TimeAndDate;
