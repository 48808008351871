import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody, Collapse
} from "reactstrap";
import {Routes, Route, useNavigate} from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
import {UserAuthContextProvider} from "./context/UserAuthContext";
import Dashboard from "./views/Dashboard";
import MyNavbar from "./components/MyNavbar";
import Couple from "./views/Couple";
import React, {useEffect, useState} from "react";
import { ReactComponent as CoupleImage } from "./assets/img/couple.svg";
import SideMenuItem from "./components/SideMenuItem";
import sideMenuItems from "./assets/sideMenuItems";
import {RouteContextProvider} from "./context/RouteContext";
import BreadCrumbs from "./components/BreadCrumbs";
import _ from "lodash";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "./firebase";
import AddGuests from "./views/AddGuest";
import Guests from "./views/Guests";
import Location from "./views/Location";
import Witnesses from "./views/Witnesses";
import TablePlan from "./views/TablePlan";
import FoodMenu from "./views/FoodMenu";
import Decorations from "./views/Decorations";
import Music from "./views/Music";
import Itinerary from "./views/Itinerary";
import Budget from "./views/Budget";

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            setUser(currentuser);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        if (windowSize[0] > 768) {
            setIsOpen(true);
        } else if (windowSize[0] <= 768) {
            setIsOpen(false);
        }
    }, [windowSize])

    const onClick = (route) => {
        if (windowSize[0] <= 768) {
            setIsOpen(false);
            navigate('/' + route);
        } else {
            navigate('/' + route);
        }
    }

    const SideMenu = () => {
        if (isOpen) {
            return (
                <>
                    {
                        sideMenuItems.length &&
                        sideMenuItems.map((item) => {
                            return (
                                <SideMenuItem
                                    onClick={() => onClick(item.route)}
                                    route={item.route}
                                    name={item.name}
                                    icon={item.icon}
                                    key={item.name}
                                ></SideMenuItem>
                            )
                        })
                    }
                </>
            );
        }
        return null;
    };

    return (
        <Container fluid className={'p-0'}>
            <RouteContextProvider>
                <UserAuthContextProvider>
                    {
                        (user && !_.isEmpty(user)) ? <MyNavbar/> : null
                    }
                    <Container fluid className={!user ? 'bg-light min-vh-100' : null}>
                        {
                            (user && !_.isEmpty(user)) ?
                                <>
                                    <Row className={'mt-3'}>
                                        <Col xs={3} sm={2}>
                                            <Button color="primary"
                                                className="d-md-none"
                                                onClick={toggle}
                                                style={{height: 45}}
                                            >
                                            Menu
                                            </Button>
                                        </Col>
                                        <Col xs={9} sm={10} md={12} lg={12}>
                                            <BreadCrumbs />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" className="d-md-none hideScrollbar">
                                            <Collapse isOpen={isOpen}>
                                                <SideMenu isOpen={isOpen} />
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </> : null
                        }
                        <Row>
                            {
                                (user && !_.isEmpty(user)) ?
                                    <Col md="3" className="d-none d-md-block">
                                        <Card>
                                            <CardBody>
                                                <div className={'hideScrollbar'} style={{ height: windowSize[0] > 768 ? "calc(100vh - 250px)" : null, overflowY: "scroll" }}>
                                                    <SideMenu isOpen={isOpen} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col> : null
                            }
                            {
                                !user ?
                                    <Col>
                                        <div>
                                            <Container style={styles.headerContent}>
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardBody>
                                                                <Row>
                                                                    <Col xs={12} md={6} className={'d-flex align-items-center justify-content-center h-100 w-100'}>
                                                                        <div style={{width: '80%'}}>
                                                                            <CoupleImage />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} md={6}>
                                                                        <div className={'d-flex align-items-center justify-content-center h-100 w-100'}>
                                                                            <Routes>
                                                                                <Route path="/" element={<Login/>}/>
                                                                            </Routes>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </Col> :
                                    <Col md={(user && !_.isEmpty(user)) ? '9' : '12'}>
                                        <Card>
                                            <CardBody>
                                                <div className={'hideScrollbar'} style={{ height: windowSize[0] > 768 ? "calc(100vh - 250px)" : null, overflowY: "scroll", overflowX: 'hidden' }}>
                                                    <Routes>
                                                        <Route
                                                            path="/dashboard"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Dashboard />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/couple"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Couple />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/location"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Location />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/guests"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Guests />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/add-guests"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <AddGuests />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/table-plan"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <TablePlan />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/witnesses"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Witnesses />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/food-menu"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <FoodMenu />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/decorations"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Decorations />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/music"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Music />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/budget"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Budget />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route
                                                            path="/itinerary"
                                                            element={
                                                                <ProtectedRoute>
                                                                    <Itinerary />
                                                                </ProtectedRoute>
                                                            }
                                                        />
                                                        <Route path="/" element={<Login/>}/>
                                                        <Route path="/signup" element={<Signup/>}/>
                                                    </Routes>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }
                        </Row>
                    </Container>
                </UserAuthContextProvider>
            </RouteContextProvider>
        </Container>
    );
}

const styles = {
    headerContent: {
        padding: '9rem 0'
    },
};


export default App;
