import React, { useState, useEffect } from 'react';
import {
    Table, Pagination, PaginationItem, PaginationLink, Button,
} from 'reactstrap';
import _ from 'lodash';
import {onValue, ref} from 'firebase/database';
import { useUserAuth } from '../context/UserAuthContext';
import { db } from '../firebase';
import EditGuest from './EditGuest';
import DeleteGuest from "./DeleteGuest";
import { ReactComponent as GuestsImage } from "./../assets/img/guestsImage.svg";

function GuestsTable() {
    const { user } = useUserAuth();
    const [guests, setGuests] = useState([]);
    const [totalNumberOfGuests, setTotalNumberOfGuests] = useState(null);
    const [currentGuests, setCurrentGuests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [guestsPerPage] = useState(8);
    const [totalPages, setTotalPages] = useState(null);
    const indexOfLastGuest = currentPage * guestsPerPage;
    const indexOfFirstGuest = indexOfLastGuest - guestsPerPage;
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const toggle = (guest) => {
        setSelectedGuest(guest);
        setIsOpen(!isOpen);
    };

    const toggleDelete = (guest) => {
        setSelectedGuest(guest);
        setIsDeleteOpen(!isDeleteOpen);
    };

    useEffect(() => {
        if (user && !_.isEmpty(user)) {
            if (user && !_.isEmpty(user)) {
                onValue(ref(db, `/users/${user.uid}/guests/`), (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const convertedData = Object.entries(data)
                            .map(([key, value]) => ({ ...value, uuid: key }));
                        setGuests(convertedData);

                        const sumOfGuests = convertedData.reduce((a, b) => ({amountOfPeople: a.amountOfPeople + b.amountOfPeople}));

                        setTotalNumberOfGuests(sumOfGuests?.amountOfPeople || 0);
                        setTotalPages(Math.ceil(convertedData.length / guestsPerPage));
                    }
                });
            }
        }
    }, [guestsPerPage, user]);

    useEffect(() => {
        if (guests && guests.length) {
            setCurrentGuests(guests.slice(indexOfFirstGuest, indexOfLastGuest));
        }
    }, [guests, indexOfFirstGuest, indexOfLastGuest]);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (guests.length < 1) {
        return (
            <div className="text-center align-items-center justify-content-center">
                <div style={{width: '40%', marginLeft: "auto", marginRight: "auto", textAlign: 'center'}}>
                    <GuestsImage />
                </div>
                <h3>You can see a list of your guests here, but...</h3>
                <h5>...first your need to add some of them.</h5>
            </div>
        )
    }

    return (
        <>
            <Table
                hover
                size="sm"
                responsive
                striped
            >
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th style={{ textAlign: 'right' }}>Headcount</th>
                        <th style={{ textAlign: 'right' }}>Plus One</th>
                        <th style={{ textAlign: 'right' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentGuests.length ? currentGuests.map((guest, i) => (
                        <tr key={i}>
                            <td
                                className="align-middle"
                                style={{ textTransform: 'capitalize' }}
                            >
                                {guest?.familyName ? 'Family' : 'Individual'}
                            </td>
                            <td className="align-middle">
                                {guest?.familyName ? guest?.familyName : guest?.fullName}
                            </td>
                            <td
                                align="right"
                                className="align-middle"
                            >
                                {guest.amountOfPeople}
                            </td>
                            <td
                                align="right"
                                className="align-middle"
                            >
                                {guest?.plusOne ? guest?.plusOneName : '-'}
                            </td>
                            <td align="right">
                                <div className="float-right">
                                    <Button
                                        size="sm"
                                        outline
                                        color="primary"
                                        className="mr-lg-2"
                                        onClick={() => toggle(guest)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        size="sm"
                                        outline
                                        color="danger"
                                        onClick={() => toggleDelete(guest)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    )) : null}
                    <tr>
                        <td colSpan={4}><strong>Total number of guests</strong></td>
                        <td align="right"><strong>{totalNumberOfGuests}</strong></td>
                    </tr>
                </tbody>
            </Table>
            <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous onClick={() => handleClick(currentPage - 1)} />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                    <PaginationItem key={i} active={i + 1 === currentPage}>
                        <PaginationLink onClick={() => handleClick(i + 1)}>
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink next onClick={() => handleClick(currentPage + 1)} />
                </PaginationItem>
            </Pagination>
            {
                (selectedGuest && isOpen) ? <EditGuest isOpen={isOpen} toggle={toggle} data={selectedGuest} /> : null
            }
            {
                (selectedGuest && isDeleteOpen) ? <DeleteGuest isOpen={isDeleteOpen} toggle={toggleDelete} data={selectedGuest} /> : null
            }
        </>
    );
}

export default GuestsTable;
