import React, {useEffect, useState} from 'react';
import {
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    InputGroup,
    InputGroupText,
    Row,
    Alert,
    Card,
    CardTitle, CardSubtitle, CardBody
} from 'reactstrap';
import {db} from "../firebase";
import {set, ref, push} from "firebase/database";
import {useUserAuth} from "../context/UserAuthContext";
import person from "./../assets/img/person.png";
import family from './../assets/img/family.png';
import {useNavigate} from "react-router-dom";

const AddGuests = () => {
    const [guestType, setGuestType] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [amountOfPeople, setAmountOfPeople] = useState('');
    const [fullName, setFullName] = useState('');
    const [plusOneName, setPlusOneName] = useState('');
    const [plusOne, setPlusOne] = useState(false);
    const [error, setError] = useState("");
    const {user} = useUserAuth();
    const guestListRef = ref(db, `/users/${user.uid}/guests/`);
    const [newGuestRef, setNewGuestRef] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        if (!!user && user.uid) {
            // eslint-disable-next-line default-case
            switch (guestType) {
            case 'family':
                set(newGuestRef, {
                    familyName: familyName,
                    amountOfPeople: parseInt(amountOfPeople)
                })
                    .then(() => {
                        setGuestType("");
                        setFullName('');
                        setPlusOneName('');
                        setPlusOne(false)
                        setFamilyName('');
                        setAmountOfPeople('');
                        navigate('/guests');
                    })
                    .catch((err) => {
                        setError(err.message)
                    })
                break;
            case 'person':
                const data = () => {
                    if (plusOne) {
                        return {
                            fullName: fullName,
                            plusOne: true,
                            plusOneName: plusOneName,
                            amountOfPeople: 2
                        }
                    } else {
                        return {
                            fullName: fullName,
                            plusOne: false,
                            amountOfPeople: 1
                        }
                    }
                }
                set(newGuestRef, data())
                    .then(() => {
                        setGuestType("");
                        setFullName('');
                        setPlusOneName('');
                        setPlusOne(false)
                        setFamilyName('');
                        setAmountOfPeople('');
                        navigate('/guests');
                    })
                    .catch((err) => {
                        setError(err.message)
                    })
                break;
            }
        }
    };

    useEffect(() => {
        let newGuestReference = push(guestListRef);
        setNewGuestRef(newGuestReference);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const resetStates = () => {
            switch (guestType) {
            case 'family':
                setFullName('');
                setPlusOneName('');
                setPlusOne(false)
                break;
            case 'person':
                setFamilyName('');
                setAmountOfPeople('');
                break;
            default:
                setFullName('');
                setPlusOneName('');
                setPlusOne(false)
                setFamilyName('');
                setAmountOfPeople('');
                break;
            }
        };

        resetStates();
    }, [guestType]);

    const guestTypeCards = [
        { type: 'family', title: 'Family', subtitle: 'Add a family', icon: family },
        { type: 'person', title: 'Individual', subtitle: 'Add an individual', icon: person },
    ];

    return (
        <div>
            <Row>
                <Col>
                    <h5>Add guests</h5>
                </Col>
            </Row>
            <Row>
                {
                    guestType === '' &&
                    <>
                        {guestTypeCards.map(({ type, title, subtitle, icon }) => (
                            <Col xs={12} sm={12} md={6} lg={6} key={type} style={{cursor: 'pointer'}}>
                                <Card
                                    className={'mb-3 outlineCard no-shadow'}
                                    onClick={() => setGuestType(type)}>
                                    <CardBody>
                                        <Col className={'d-flex flex-column justify-content-between align-items-center text-center'}>
                                            <img src={icon} alt={title} height={300} width={300} className={'mb-3 img-fluid'}/>
                                            <div>
                                                <CardTitle tag="h5">{title}</CardTitle>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">{subtitle}</CardSubtitle>
                                            </div>
                                        </Col>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </>
                }
            </Row>
            <Row>
                <Col xs={12} sm={8} md={8} lg={8}>
                    {guestType === 'family' && (
                        <>
                            <FormGroup row>
                                <Label for="familyName" sm={2}>
                                    Family Name
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="familyName"
                                        id="familyName"
                                        value={familyName}
                                        onChange={(e) => setFamilyName(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="amountOfPeople" sm={2}>
                                    Amount of People
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        name="amountOfPeople"
                                        id="amountOfPeople"
                                        value={amountOfPeople}
                                        onChange={(e) => setAmountOfPeople(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                        </>
                    )}
                    {guestType === 'person' && (
                        <>
                            <FormGroup row>
                                <Label for="fullName" sm={2}>
                                    Full Name
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="fullName"
                                        id="fullName"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="plusOne" sm={2}>
                                    Plus One
                                </Label>
                                <Col sm={10}>
                                    <InputGroup>
                                        <InputGroupText>
                                            <Input
                                                addon
                                                type="checkbox"
                                                name="plusOne"
                                                id="plusOne"
                                                checked={plusOne}
                                                onChange={(e) => setPlusOne(e.target.checked)}
                                            />
                                        </InputGroupText>
                                        <Input
                                            disabled={!plusOne}
                                            placeholder={'Please select the checkbox and type in the full name of your plus one'}
                                            type="text"
                                            name="plusOneName"
                                            id="plusOneName"
                                            value={plusOneName}
                                            onChange={(e) => setPlusOneName(e.target.value)}
                                        />
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                        </>
                    )}
                    {
                        guestType !== '' &&
                        <FormGroup row>
                            <Col sm={{ size: 10, offset: 2 }}>
                                <Button color="primary" onClick={() => handleSubmit()}>
                                    Submit
                                </Button>
                            </Col>
                        </FormGroup>
                    }
                </Col>
            </Row>
            {
                error &&
                <Row className={'mt-4'}>
                    <Col>
                        <Alert variant="danger">{error}</Alert>
                    </Col>
                </Row>
            }
        </div>
    );
};

export default AddGuests;
