import React, {useEffect, useState} from 'react';
import {Button, Form, FormGroup, Label, Input, Alert} from 'reactstrap';
import {Spotify} from "react-spotify-embed";
import {db} from "../firebase";
import {onValue, ref, update} from "firebase/database";
import {useUserAuth} from "../context/UserAuthContext";

const CeremonyReceptionMusic = () => {
    const {user} = useUserAuth();
    const [ceremonyMusic, setCeremonyMusic] = useState('');
    const [receptionMusic, setReceptionMusic] = useState('');
    const [urlError, setUrlError] = useState('');

    useEffect(() => {
        onValue(ref(db, `/users/${user.uid}/music/ceremony_reception`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setCeremonyMusic(data?.ceremonyMusic || '')
                setReceptionMusic(data?.receptionMusic || '')
            }
        });
    }, [user]);


    const updateMusic = async () => {
        if (!!user.uid) {
            await update(ref(db, `/users/${user.uid}/music/ceremony_reception`), {
                ceremonyMusic,
                receptionMusic
            })
        }
    };

    const handleInputChange = (event) => {
        // Regular expression for Spotify track URL validation
        const spotifyUrlRegex = /^(https:\/\/open\.spotify\.com\/track\/)[a-zA-Z0-9]+(\?.*)?$/;

        if ((event.target.name === 'ceremonyMusic' || event.target.name === 'receptionMusic') && !spotifyUrlRegex.test(event.target.value)) {
            setUrlError('Please enter a valid Spotify track URL.');
        } else {
            setUrlError(null); // clear the error if the URL is valid
        }

        switch(event.target.name) {
        case 'ceremonyMusic':
            setCeremonyMusic(event.target.value);
            break;
        case 'receptionMusic':
            setReceptionMusic(event.target.value);
            break;
        default:
            break;
        }
    };

    useEffect(() => {
        if ((ceremonyMusic || receptionMusic) === '') {
            setUrlError('')
        }
    }, [ceremonyMusic, receptionMusic])

    const handleSubmit = async (event) => {
        event.preventDefault();

        await updateMusic();
    };

    const editSongs = () => {
        setCeremonyMusic('');
        setReceptionMusic('');
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="ceremonyMusic">Ceremony Song</Label>
                    {
                        ceremonyMusic && !urlError ? <Spotify wide link={ceremonyMusic} /> :
                            <Input type="url"
                                name="ceremonyMusic"
                                id="ceremonyMusic"
                                value={ceremonyMusic}
                                onChange={handleInputChange}
                                placeholder={'Enter a Spotify song link'}
                            />
                    }
                </FormGroup>
                <FormGroup>
                    <Label for="receptionMusic">Reception Music</Label>
                    {
                        receptionMusic && !urlError ? <Spotify wide link={receptionMusic} /> :
                            <Input type="url"
                                name="receptionMusic"
                                id="receptionMusic"
                                value={receptionMusic}
                                onChange={handleInputChange}
                                placeholder={'Enter a Spotify song link'}
                            />
                    }
                </FormGroup>
                <Button
                    color={'primary'}
                    className={'mr-2'}
                    type={'submit'}
                    disabled={!(ceremonyMusic || receptionMusic) || !!urlError}
                >Save songs
                </Button>
                <Button type={'reset'} onClick={editSongs}>Edit songs</Button>
            </Form>
            {
                urlError ?
                    <div className={'pt-2'}>
                        <Alert variant="danger">{urlError}</Alert>
                    </div>
                    : null
            }
        </div>
    );
}

export default CeremonyReceptionMusic;
