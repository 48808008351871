import React, {useEffect} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {auth, db} from "../firebase"
import { onValue, ref } from "firebase/database";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import {onAuthStateChanged} from "firebase/auth";
import { ReactComponent as Couple } from "./../assets/img/couple.svg";

const Dashboards = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && !_.isEmpty(user)) {
                onValue(ref(db, `/users/${user.uid}`), (snapshot) => {
                    const data = snapshot.val();
                });
            } else if (!user) {
                navigate("/");
            }
        });

        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="text-center align-items-center justify-content-center">
                            <div style={{width: '35%', marginLeft: "auto", marginRight: "auto", textAlign: 'center'}}>
                                <Couple/>
                            </div>
                            <h3>Welcome to <strong>MyWeddingPlanner</strong> app</h3>
                            <h5>Get started by telling us your names</h5>
                            <Button color={'primary'} onClick={() => navigate('/couple')}>Start</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Dashboards;
