import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {useUserAuth} from "../context/UserAuthContext";
import { db, storage } from '../firebase';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { set, ref as dbRef, push } from "firebase/database";

const BudgetForm = () => {
    const {user} = useUserAuth();
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('');
    const [invoice, setInvoice] = useState(null);
    const [invoiceURL, setInvoiceURL] = useState('');

    const handleFileChange = e => {
        setInvoice(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(invoice) {
            const storageRef = ref(storage, `invoices/${user.uid}/${invoice.name}`);
            await uploadBytes(storageRef, invoice);
            const downloadURL = await getDownloadURL(storageRef);
            setInvoiceURL(downloadURL);

            const cost = {
                name,
                amount,
                currency,
                invoiceURL: downloadURL
            };

            const newCostRef = push(dbRef(db, `/users/${user.uid}/budget/`));
            await set(newCostRef, cost);
            setName('');
            setAmount('');
            setCurrency('');
            setInvoice(null);
            setInvoiceURL('');
        }
    };


    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="name">Cost Name</Label>
                <Input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
            </FormGroup>
            <FormGroup>
                <Label for="amount">Amount</Label>
                <Input type="number" name="amount" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
            </FormGroup>
            <FormGroup>
                <Label for="currency">Currency</Label>
                <Input type="text" name="currency" id="currency" value={currency} onChange={(e) => setCurrency(e.target.value)} />
            </FormGroup>
            <FormGroup>
                <Label for="invoice">Invoice</Label>
                <Input type="file" name="invoice" id="invoice" onChange={handleFileChange} />
            </FormGroup>
            <Button>Save Cost</Button>
        </Form>
    );
}

export default BudgetForm;
