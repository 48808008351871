import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Alert, CardTitle, Col, Row} from "reactstrap";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../context/UserAuthContext";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../firebase";
import logo from "../assets/img/logo.png";

const Login = () => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true)
    const { googleSignIn } = useUserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            if (currentuser) {
                navigate('/dashboard')
            } else {
                setLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
            await googleSignIn();
            navigate("/dashboard");
        } catch (error) {
            setError(error.message)
            console.log(error.message);
        }
    };

    if (loading) {
        return (
            <h4>Loading...</h4>
        )
    }

    return (
        <Row className={'d-flex justify-content-center'}>
            <Col>
                <Row className={'text-center pb-4'}>
                    <Col lg={12}>
                        <img src={logo} height={50} alt="MyWeddingPlanner Logo" />
                    </Col>
                </Row>
                <Row className={'mt-2'}>
                    <Col className={'d-flex justify-content-center'}>
                        <GoogleButton
                            className="g-btn align-self-center"
                            type="dark"
                            onClick={handleGoogleSignIn}
                        />
                    </Col>
                </Row>
                {
                    error &&
              <Row className={'mt-4'}>
                  <Col>
                      <Alert variant="danger">{error}</Alert>
                  </Col>
              </Row>
                }
            </Col>
        </Row>
    );
};

export default Login;
