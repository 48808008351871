import React, {useEffect, useState} from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {onValue, ref, update} from "firebase/database";
import {db} from "../firebase";
import {useUserAuth} from "../context/UserAuthContext";

const Food = () => {
    const {user} = useUserAuth();
    const [firstCourse, setFirstCourse] = useState('');
    const [secondCourse, setSecondCourse] = useState('');
    const [thirdCourse, setThirdCourse] = useState('');
    const [dessert, setDessert] = useState('');
    const [snacks, setSnacks] = useState('');

    useEffect(() => {
        onValue(ref(db, `/users/${user.uid}/menu/food`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setFirstCourse(data?.firstCourse || '');
                setSecondCourse(data?.secondCourse || '');
                setThirdCourse(data?.thirdCourse || '');
                setDessert(data?.dessert || '');
                setSnacks(data?.snacks || '')
            }
        });
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            firstCourse,
            secondCourse,
            thirdCourse,
            dessert,
            snacks
        };

        await update(ref(db, `/users/${user.uid}/menu/`), {
            food: data
        })
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="firstCourse">First Course (Cold appetizer)</Label>
                <Input
                    type="text"
                    name="firstCourse"
                    id="firstCourse"
                    value={firstCourse}
                    onChange={(e) => setFirstCourse(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="secondCourse">Second Course (Hot appetizer)</Label>
                <Input
                    type="text"
                    name="secondCourse"
                    id="secondCourse"
                    value={secondCourse}
                    onChange={(e) => setSecondCourse(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="thirdCourse">Third Course (Main Course)</Label>
                <Input
                    type="text"
                    name="thirdCourse"
                    id="thirdCourse"
                    value={thirdCourse}
                    onChange={(e) => setThirdCourse(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="dessert">Dessert</Label>
                <Input
                    type="text"
                    name="dessert"
                    id="dessert"
                    value={dessert}
                    onChange={(e) => setDessert(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="snacks">Snacks</Label>
                <Input
                    type="text"
                    name="snacks"
                    id="snacks"
                    value={snacks}
                    onChange={(e) => setSnacks(e.target.value)}
                />
            </FormGroup>
            <Button>Save Menu</Button>
        </Form>
    );
}

export default Food;
