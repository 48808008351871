import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'reactstrap';
import { ReactComponent as Band } from "./../assets/img/band.svg";
import ArtistInfo from "../components/ArtistInfo";
import CeremonyReceptionMusic from "../components/CeremonyReceptionMusic";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import {useUserAuth} from "../context/UserAuthContext";
import Playlist from "../components/Playlist";

const Music = () => {
    const {user} = useUserAuth();
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        onValue(ref(db, `/users/${user.uid}/music`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setHidden(false)
            }
        });
    }, [user]);

    if (hidden) {
        return (
            <Row>
                <Col>
                    <div className="text-center align-items-center justify-content-center">
                        <div style={{width: '35%', marginLeft: "auto", marginRight: "auto", textAlign: 'center'}}>
                            <Band />
                        </div>
                        <h3>A little party never killed nobody...</h3>
                        <h5>...start by entering some of your favourite songs</h5>
                        <Button color={'primary'} onClick={() => setHidden(false)}>Start</Button>
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <div>
            <Row>
                <Col>
                    <h5 className={'mb-4'}>Ceremony & Reception Songs</h5>
                    <div className={'outlineCardNoHover p-4'}>
                        <CeremonyReceptionMusic />
                    </div>
                </Col>
                <Col>
                    <h5 className={'mb-4'}>Band / DJ Info</h5>
                    <div className={'outlineCardNoHover p-4'}>
                        <ArtistInfo />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Playlist />
                </Col>
            </Row>
        </div>
    );
};

export default Music;
