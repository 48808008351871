import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button, Card, CardBody
} from 'reactstrap';
import {onAuthStateChanged} from "firebase/auth";
import {auth, db} from "../firebase";
import _ from "lodash";
import {onValue, ref, update} from "firebase/database";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Bride } from "./../assets/img/bride.svg";
import { ReactComponent as Groom } from "./../assets/img/groom.svg";

const Couple = () => {
    const navigate = useNavigate();
    const [groomName, setGroomName] = useState('');
    const [brideName, setBrideName] = useState('');
    const [userID, setUserID] = useState('')

    const handleGroomNameChange = (e) => {
        setGroomName(e.target.value);
    };

    const handleBrideNameChange = (e) => {
        setBrideName(e.target.value);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && !_.isEmpty(user)) {
                setUserID(user.uid);
                onValue(ref(db, `/users/${user.uid}/couple`), (snapshot) => {
                    const data = snapshot.val();
                    setBrideName(data?.bride || '');
                    setGroomName(data?.groom || '');
                });
            } else if (!user) {
                navigate("/");
            }
        });

        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const updateCouple = async () => {
        if (!!userID) {
            await update(ref(db, `/users/${userID}/couple`), {
                bride: brideName,
                groom: groomName
            })
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <h5>Couple</h5>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <Card className={'mb-3 outlineCardNoHover no-shadow'}>
                        <CardBody>
                            <Col className={'d-flex flex-column justify-content-between'}>
                                <div className={'text-center'}>
                                    <Bride height={170} width={170} className={'mb-3 img-fluid'}/>
                                </div>
                                <div>
                                    <FormGroup>
                                        <Label for="brideName">Bride's Name:</Label>
                                        <Input type="text" name="brideName" id="brideName" value={brideName} onChange={handleBrideNameChange} />
                                    </FormGroup>
                                </div>
                            </Col>
                        </CardBody>
                    </Card>
                </Col><Col xs={12} sm={12} md={6} lg={6}>
                    <Card className={'mb-3 outlineCardNoHover no-shadow'}>
                        <CardBody>
                            <Col className={'d-flex flex-column justify-content-between'}>
                                <div className={'text-center'}>
                                    <Groom height={170} width={170} className={'mb-3 img-fluid'}/>
                                </div>
                                <div>
                                    <FormGroup>
                                        <Label for="groomName">Groom's Name:</Label>
                                        <Input type="text" name="groomName" id="groomName" value={groomName} onChange={handleGroomNameChange} />
                                    </FormGroup>
                                </div>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="primary"
                        type="submit"
                        block
                        onClick={updateCouple}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default Couple;
