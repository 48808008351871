import React, { useState, useEffect } from 'react';
import {Button, Form, FormGroup, Label, Input, Table, Row, Col} from 'reactstrap';
import { onValue, ref, update } from "firebase/database";
import { db } from "../firebase";
import { useUserAuth } from "../context/UserAuthContext";

const Itinerary = () => {
    const { user } = useUserAuth();
    const [events, setEvents] = useState([]);
    const [selectedEventIndex, setSelectedEventIndex] = useState(null);
    const [eventName, setEventName] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    useEffect(() => {
        onValue(ref(db, `/users/${user.uid}/music/itinerary`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setEvents(data.events);
            }
        });
    }, [user]);

    const addEvent = () => {
        const newEvents = [...events, { eventName, startTime, endTime }];
        setEvents(newEvents);
        updateData(newEvents);
        resetForm();
    };

    const editEvent = (index) => {
        setSelectedEventIndex(index);
        setEventName(events[index].eventName);
        setStartTime(events[index].startTime);
        setEndTime(events[index].endTime);
    };

    const saveEvent = () => {
        const newEvents = [...events];
        newEvents[selectedEventIndex] = { eventName, startTime, endTime };
        setEvents(newEvents);
        updateData(newEvents);
        resetForm();
    };

    const deleteEvent = (index) => {
        const newEvents = [...events];
        newEvents.splice(index, 1);
        setEvents(newEvents);
        updateData(newEvents);
    };

    const updateData = (newEvents) => {
        if (!!user.uid) {
            update(ref(db, `/users/${user.uid}/music/itinerary`), {
                events: newEvents
            });
        }
    };

    const resetForm = () => {
        setSelectedEventIndex(null);
        setEventName('');
        setStartTime('');
        setEndTime('');
    };

    return (
        <Row>
            <Col md={4}>
                <h5 className={'mb-4'}>Add new event</h5>
                <div className={'outlineCardNoHover p-4'}>
                    <Form>
                        <FormGroup>
                            <Label for="eventName">Event Name</Label>
                            <Input type="text" name="eventName" id="eventName" value={eventName} onChange={e => setEventName(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="startTime">Start Time</Label>
                            <Input type="time" name="startTime" id="startTime" value={startTime} onChange={e => setStartTime(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="endTime">End Time</Label>
                            <Input type="time" name="endTime" id="endTime" value={endTime} onChange={e => setEndTime(e.target.value)} />
                        </FormGroup>
                        {
                            selectedEventIndex === null ?
                                <Button type="button" onClick={addEvent}>Add Event</Button> :
                                <Button type="button" onClick={saveEvent}>Save Event</Button>
                        }
                    </Form>
                </div>
            </Col>
            <Col md={8}>
                <h5 className={'mb-4'}>Itinerary</h5>
                <div className={'outlineCardNoHover p-4'}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Event Name</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th style={{ textAlign: 'right' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events.map((event, index) => (
                                <tr key={index}>
                                    <td>{event.eventName}</td>
                                    <td>{event.startTime}</td>
                                    <td>{event.endTime}</td>
                                    <td align="right">
                                        <div className="float-right">
                                            <Button
                                                size="sm"
                                                outline
                                                color="primary"
                                                className="mr-lg-2"
                                                onClick={() => editEvent(index)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                size="sm"
                                                outline
                                                color="danger"
                                                onClick={() => deleteEvent(index)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
};

export default Itinerary;
