import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputGroup} from 'reactstrap';
import {onValue, ref, update} from "firebase/database";
import {db} from "../firebase";
import {useUserAuth} from "../context/UserAuthContext";

const Drinks = () => {
    const {user} = useUserAuth();
    const [drinks, setDrinks] = useState([]);
    const [drinkName, setDrinkName] = useState('');

    useEffect(() => {
        onValue(ref(db, `/users/${user.uid}/menu/drinks`), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setDrinks(data)
            }
        });
    }, [user]);

    const deleteDrink = (index) => {
        const newDrinks = drinks.filter((drink, i) => i !== index);

        update(ref(db, `/users/${user.uid}/menu/`), {
            drinks: newDrinks
        })

        setDrinks(newDrinks);
    }

    const addDrink = () => {
        setDrinks([...drinks, drinkName]);
        let newDrinks = [...drinks, drinkName];

        update(ref(db, `/users/${user.uid}/menu/`), {
            drinks: newDrinks
        })
        setDrinkName('');
    }

    return (
        <>
            <Form onSubmit={addDrink}>
                <InputGroup>
                    <Input
                        type="text"
                        name="drinkName"
                        id="drinkName"
                        value={drinkName}
                        placeholder={'Add a new drink'}
                        onChange={(e) => setDrinkName(e.target.value)}
                    />
                    <Button type="button" onClick={() => addDrink()} className={'ml-2'}>Add Drink</Button>
                </InputGroup>
            </Form>
            {
                drinks.length ? <hr /> : null
            }
            {drinks.map((drink, index) => (
                <div key={index} className={'mb-2'}>
                    <InputGroup>
                        <Input bsSize='sm' type="text" name="drink" id={`drink-${index}`} value={drink} readOnly />
                        <Button type="button" outline size={'sm'} color={'danger'} onClick={() => deleteDrink(index)} className={'ml-2'}>Remove</Button>
                    </InputGroup>
                </div>
            ))}
        </>
    );
}

export default Drinks;
