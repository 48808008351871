import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {activeRouteContext} from "../context/RouteContext";
import React, {useEffect, useState} from "react";
import sideMenuItems from "../assets/sideMenuItems";

const BreadCrumbs = () => {
    const [viewName, setViewName] = useState(null);
    let {activeRoute} = activeRouteContext();

    useEffect(() => {
        const potentialRoutes = [...sideMenuItems, {
            route: 'add-guests',
            name: 'Add Guests',
        }]

        potentialRoutes.forEach((item) => {
            if (('/' + item.route) === activeRoute) {
                setViewName(item.name)
            }
        })

    }, [activeRoute])

    return (
        <Breadcrumb>
            <BreadcrumbItem active><a href={'/'}>Home</a></BreadcrumbItem>
            <BreadcrumbItem><a href={activeRoute}>{!viewName ? '' : viewName}</a></BreadcrumbItem>
        </Breadcrumb>
    )
}

export default BreadCrumbs;
