import React from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button,
} from 'reactstrap';
import _ from 'lodash';
import { BiUserCircle } from 'react-icons/bi';

import logo from './../assets/img/logo.png';
import {useUserAuth} from "../context/UserAuthContext";
import {useNavigate} from "react-router";

const MyNavbar = (props) => {
    const { logOut, user } = useUserAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/">
                    <img src={logo} height={30} alt="MyWeddingPlanner Logo" />
                </NavbarBrand>
                <Nav className="ml-auto" navbar>
                    <NavItem className={'d-none d-md-block'}>
                        <NavLink>Hello
                            {(!_.isEmpty(user) && user?.displayName) ? ', ' + user.displayName.split(" ")[0] : ', User'}
                            {
                                !_.isEmpty(user) && user?.photoURL ?
                                    <img
                                        src={user.photoURL}
                                        height="40"
                                        alt="Your Avatar"
                                        className="rounded-circle ml-3 mr-3"
                                    />
                                    :
                                    <BiUserCircle size={50} />
                            }
                        </NavLink>
                    </NavItem>
                    {
                        !_.isEmpty(user) &&
                        <NavItem className={'mt-lg-auto mb-lg-auto'}>
                            <Button
                                size={'sm'}
                                outline
                                onClick={handleLogout}>
                                Log out
                            </Button>
                        </NavItem>
                    }
                </Nav>
            </Navbar>
        </div>
    );
};

export default MyNavbar;
