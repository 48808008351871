import React, {useEffect, useState} from 'react';
import {Table} from 'reactstrap';
import BudgetItem from './BudgetItem';
import {useUserAuth} from "../context/UserAuthContext";
import {db} from '../firebase';
import {onValue, ref as dbRef} from "firebase/database";

const BudgetList = () => {
    const {user} = useUserAuth();
    const [costs, setCosts] = useState([]);

    useEffect(() => {
        if (user) {
            const costRef = dbRef(db, `/users/${user.uid}/budget/`);
            onValue(costRef, (snapshot) => {
                const costObjects = snapshot.val();
                const costList = costObjects ? Object.keys(costObjects).map(key => ({
                    ...costObjects[key],
                    id: key
                })) : [];
                setCosts(costList);
            });
        }
    }, [user]);

    const sumAmounts = (array) => {
        return array.reduce((sum, item) => sum + parseFloat(item.amount), 0);
    }

    return (
        <Table striped>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th style={{ textAlign: 'right' }}>
                        Invoice
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {costs.map(cost => <BudgetItem key={cost.id} cost={cost} costKey={cost.id}/>)}
                {
                    costs.length > 0 ?
                        <tr>
                            <td colSpan={4}><strong>SUM:</strong></td>
                            <td align={'right'}><strong>{sumAmounts(costs)}</strong></td>
                        </tr>
                        :
                        null
                }
            </tbody>
        </Table>
    );
}

export default BudgetList;
