import React from 'react';
import { useDrop } from 'react-dnd';
import {MdFamilyRestroom, MdPeople, MdPerson} from "react-icons/md";
import {IoIosCloseCircleOutline} from 'react-icons/io';
import {IoIosCloseCircle} from 'react-icons/io';

const Table = ({ table, onGuestDrop, seats, handleGuestUnassign, seatedGuests, onRemoveTable }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'guest',
        drop: (item) => onGuestDrop(item.guest, table),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const availableSeats = () => {
        const occupiedSeats = seatedGuests.reduce((acc, currGuest) => acc + currGuest.amountOfPeople, 0);
        return Number.isNaN(seats - occupiedSeats) ? 0 : seats - occupiedSeats;
    };

    const handleUnassign = (guest) => {
        handleGuestUnassign(guest, table.id);
    };

    const handleRemoveTable = () => {
        onRemoveTable(table.id);
    };

    const guestInfo = (guest, onClick) => {
        if (guest.familyName) {
            return <div key={guest.id}><span><MdFamilyRestroom />{' ' + guest.familyName} ({guest.amountOfPeople})</span><span onClick={onClick}> <IoIosCloseCircleOutline /></span></div>;
        } else if (guest.plusOneName) {
            return <div key={guest.id}><span><MdPeople /> {guest.fullName} + {guest.plusOneName} (2)</span><span onClick={onClick}> <IoIosCloseCircleOutline /></span></div>;
        } else {
            return <div key={guest.id}><span><MdPerson />{' ' + guest.fullName} (1)</span><span onClick={onClick}> <IoIosCloseCircleOutline /></span></div>;
        }
    }

    return (
        <div ref={drop} className="mb-3">
            <div className={'tableCard p-2'} style={{ backgroundColor: isOver ? '#e8e8e8' : 'white' }}>
                <div className="d-flex justify-content-between">
                    <h6>{table.name}</h6>
                    <span style={{cursor: 'pointer'}} onClick={handleRemoveTable}><IoIosCloseCircle color="danger" /></span>
                </div>
                <div className="table-guests">
                    {seatedGuests
                        .map((guest) => (
                            guestInfo(guest, () => handleUnassign(guest))
                        ))}
                </div>
                <div className="text-right mt-1">
                    <small>Available seats: {availableSeats()}</small>
                </div>
            </div>
        </div>
    );
};

export default Table;
