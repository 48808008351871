import React, { useState } from 'react';
import Guest from './Guest';
import {Input} from "reactstrap";

const GuestList = ({ guests, onGuestRemove }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filterGuests = () => {
        return guests.filter((guest) => {
            const fullName = `${guest.fullName}${guest.plusOneName ? ' + ' + guest.plusOneName : ''}`;
            const familyName = guest.familyName || '';
            return fullName.toLowerCase().includes(searchTerm.toLowerCase()) || familyName.toLowerCase().includes(searchTerm.toLowerCase());
        });
    };

    return (
        <div>
            <div className="mb-3">
                <Input
                    type="text"
                    placeholder="Search for a guest..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="hideScrollbar" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 370px)' }}>
                {filterGuests().map((guest) => (
                    <Guest key={guest.id} guest={guest} onGuestRemove={onGuestRemove}/>
                ))}
            </div>
        </div>
    );
};

export default GuestList;
