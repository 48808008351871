import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import GuestsTable from '../components/GuestsTable';

function Guests() {
    const navigate = useNavigate();

    return (
        <div>
            <Row>
                <Col>
                    <h5>Guest list</h5>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        onClick={() => navigate('/add-guests')}
                        size="sm"
                        className="float-right mb-3"
                    >
            Add guest
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={'outlineCardNoHover p-4'}>
                        <GuestsTable />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Guests;
